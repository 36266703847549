import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  countriesByLanguage,
  BOTONIC_STORAGE_KEY,
  faqBotonicAppId,
  faqBotonicUrl,
  languages,
  htmlHeadContents,
  languagesWithDirectHandoff,
  HANDOFF_PAYLOAD,
  newFaqBotonicAppId,
  newFaqBotonicUrl,
} from './constants'
import { InMemoryStorage } from './domain/storage/in-memory-storage'
import { getPathData, isProduction } from './utils/env'
import './assets/styles/app-faq.scss'
import { getInitialPayload } from './utils/contents'

declare global {
  interface Window {
    inMemoryStorage: Storage
    botonicOnInit: (app: any) => void | Promise<void>
    botonicOnOpen: (app: any) => void | Promise<void>
    botonicOnClose: (app: any) => void | Promise<void>
    botonicOnMessage: (app: any, message: any) => void | Promise<void>
  }
}

if (!window.inMemoryStorage) {
  window.inMemoryStorage = new InMemoryStorage()
}

const {
  language,
  languageSlug,
  sessionId,
  ivrException,
  initialContent,
  referral,
  queueId,
  loadBalancer
} = getPathData()
setLanguageInStorage(language)
const isNewBot = showNewBot(loadBalancer)

function FaqBotApp() {
  const botonicUrl = isNewBot ? newFaqBotonicUrl : faqBotonicUrl
  const botonicAppId = isNewBot ? newFaqBotonicAppId : faqBotonicAppId

  useEffect(() => {
    loadBotonic('faq-webchatRoot', botonicUrl, {
      hostId: 'faq-webchatRoot',
      appId: botonicAppId,
      onInit: (app: any) => {
        const enableUserInput = language === languages.ENGLISH
        app.updateUser({
          extra_data: {
            language,
            country: countriesByLanguage[language],
            sessionId,
            ivrException,
            referral,
          },
        })
        app.updateWebchatSettings({
          enableUserInput,
        })
        window.botonicOnInit(app)
        if (languagesWithDirectHandoff.includes(language) || ivrException) {
          app.addUserPayload(HANDOFF_PAYLOAD)
        } else if (initialContent) {
          app.addUserPayload(getInitialPayload(initialContent, { queueId }))
        } else {
          app.openCoverComponent()
        }
        app.open()
      },
      onOpen: (app: any) => {
        const enableUserInput = language === languages.ENGLISH
        app.updateUser({
          extra_data: {
            language,
            country: countriesByLanguage[language],
          },
        })
        app.updateWebchatSettings({
          enableUserInput,
        })
        window.botonicOnOpen(app)
      },
      onClose: (app: any) => {
        window.botonicOnClose(app)
        app.open()
      },
    })
  }, [])

  const headTitle = htmlHeadContents[language].title
  const headDescription = htmlHeadContents[language].description

  const baseUrl = isProduction()
    ? 'https://www.easyjet.com/chat'
    : 'https://staging-easyjet.hubtype.com/chat'

  return (
    <div id='faq-main-app-container'>
      <Helmet>
        <link
          rel='canonical'
          href={`${baseUrl}/${languageSlug.toLowerCase()}`}
        />
        <link rel='alternate' href={`${baseUrl}/en`} hrefLang='en' />
        <link rel='alternate' href={`${baseUrl}/fr`} hrefLang='fr' />
        <link rel='alternate' href={`${baseUrl}/it`} hrefLang='it' />
        <link rel='alternate' href={`${baseUrl}/de`} hrefLang='de' />
        <link rel='alternate' href={`${baseUrl}/es`} hrefLang='es' />
        <link rel='alternate' href={`${baseUrl}/pt`} hrefLang='pt' />
        <link rel='alternate' href={`${baseUrl}/nl`} hrefLang='nl' />
        <title>{headTitle}</title>
        <meta name='description' content={headDescription} />
      </Helmet>
      <div id='faq-app-container'>
      {isNewBot && !isProduction() && <div id='new-bot-staging-banner'>NEW</div>}
        <div id='faq-webchatRoot' />
      </div>
    </div>
  )
}

function setLanguageInStorage(language: string): void {
  const storage = window.inMemoryStorage.getItem(BOTONIC_STORAGE_KEY) || '{}'
  let storageJson = JSON.parse(storage)
  'session' in storageJson
    ? (storageJson.session.user.extra_data.language = language)
    : (storageJson = { session: { user: { extra_data: { language } } } })

  window.inMemoryStorage.setItem(
    BOTONIC_STORAGE_KEY,
    JSON.stringify(storageJson)
  )
}

function loadBotonic(
  elementId: string,
  botonicUrl: string,
  webchatConfig: Record<string, any>
) {
  const script = document.createElement('script')
  script.src = botonicUrl
  script.onload = () => executeBotonic(elementId, webchatConfig)
  document.head.appendChild(script)
}

function executeBotonic(elementId: string, webchatConfig: Record<string, any>) {
  //@ts-ignore
  Botonic.render(document.getElementById(elementId), webchatConfig)
}

function showNewBot(percentage: number | undefined): boolean {
  if (!percentage) return false

  return Math.random() < (percentage / 100);
}

export default FaqBotApp
